import React, { useEffect, useState } from "react";

const DataCard = ({ data }) => {
  const [imageUrl, setImageUrl] = useState(
    "https://app.firststock.fr/img/OTHER-light.png"
  );

  const formatWeight = (weight) => {
    const number = parseFloat(weight);
    return number % 1 === 0 ? `${number}€` : `${number.toFixed(2)}€`;
  };

  useEffect(() => {
    const fetchImage = async () => {
      if (data.category === "SNEAKERS" && !data.image) {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          setImageUrl("https://app.firststock.fr/img/OTHER-dark.png");
        } else {
          setImageUrl("https://app.firststock.fr/img/OTHER-light.png");
        }
      } else {
        setImageUrl(data.image);
      }
    };

    fetchImage();
  }, [data.sku, data.category]);

  return (
    <div className="data-card">
      {data.wts && (
        <h5>
          {formatWeight(data.wts)}
          {data.quantity > 1 ? ` x${data.quantity}` : ""}
        </h5>
      )}
      <div className="img">
        <img
          src={
            data.category === "SNEAKERS"
              ? imageUrl
              : data.category === "BILLETERIE"
              ? window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches
                ? "https://app.firststock.fr/img/billet-dark.png"
                : "https://app.firststock.fr/img/billet-light.png"
              : data.category === "VETEMENTS"
              ? window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches
                ? `https://app.firststock.fr/img/${data.articlevetement}-dark.png`
                : `https://app.firststock.fr/img/${data.articlevetement}-light.png`
              : data.category === "OBJETS"
              ? window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches
                ? `https://app.firststock.fr/img/${data.articleobject}-dark.png`
                : `https://app.firststock.fr/img/${data.articleobject}-light.png`
              : "https://app.firststock.fr/img/OTHER-light.png"
          }
          alt="Data"
          onError={(e) => {
            const isDarkMode =
              window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches;
            e.target.src = isDarkMode
              ? "https://app.firststock.fr/img/OTHER-dark.png"
              : "https://app.firststock.fr/img/OTHER-light.png";
          }}
        />
      </div>

      {data.category === "SNEAKERS" && <h4>{data.sku}</h4>}
      {data.category === "VETEMENTS" && <h4>{data.articlevetement}</h4>}
      <h3>{data.title}</h3>
      {(data.category === "SNEAKERS" || data.category === "VETEMENTS") && (
        <p>{data.size}</p>
      )}
      {data.category === "OBJETS" && <p>{data.articleobject}</p>}
      {data.category === "BILLETERIE" && <p>{data.dateevent}</p>}
    </div>
  );
};

export default DataCard;
